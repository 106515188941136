import React, { useEffect, useState, FormEvent, useRef } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import axios from "axios";
import bgimage from "./images/newitemspic.png";
import newitembox from "./images/newitembox.jpg";
import { useNavigate } from "react-router-dom";

axios.defaults.baseURL = `${process.env.REACT_APP_SERVER}`;

interface Subcategory {
  id: string;
  name: string;
}

interface CategoryWithSubcategories {
  category: string;
  subcategories: Subcategory[];
}

const FETCH_INTERVAL = 1000; // 1 second

const NewItemFile: React.FC = () => {
  const [itemTitle, setItemTitle] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [price, setPrice] = useState("");
  const [priceValid, setPriceValid] = useState(true);
  const [category, setCategory] = useState<string>("");
  const [subcategory, setSubcategory] = useState<string>("");
  const [itemCondition, setItemCondition] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [sellBuy, setSellBuy] = useState<string>("sell");
  const [locations, setLocations] = useState<
    { location: string; idlocation: string }[]
  >([]);
  const [idlocation, setIdlocation] = useState<string>("");
  const [categoriesWithSubcategories, setCategoriesWithSubcategories] =
    useState<CategoryWithSubcategories[]>([]);

  const lastFetchTimeRefCategories = useRef<number | null>(null);
  const lastFetchTimeRefLocations = useRef<number | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    const fetchCategories = async () => {
      const now = Date.now();
      if (
        !lastFetchTimeRefCategories.current ||
        now - lastFetchTimeRefCategories.current > FETCH_INTERVAL
      ) {
        lastFetchTimeRefCategories.current = now;
        try {
          const response = await axios.get("/api/get-categories");
          setCategoriesWithSubcategories(response.data);
        } catch (error) {
          console.error("Error fetching categories and subcategories:", error);
        }
      }
    };

    fetchCategories();
  }, [navigate]);

  useEffect(() => {
    const fetchLocations = async () => {
      const now = Date.now();
      if (
        !lastFetchTimeRefLocations.current ||
        now - lastFetchTimeRefLocations.current > FETCH_INTERVAL
      ) {
        lastFetchTimeRefLocations.current = now;
        try {
          const response = await axios.get("/api/get-locations");
          setLocations(response.data);
        } catch (error) {
          console.error("Error fetching locations:", error);
        }
      }
    };

    fetchLocations();
  }, []);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
    setSubcategory("");
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    // Etsi syötetty arvo locations-taulukosta
    const selectedLocation = locations.find((loc) => loc.location === value);

    if (selectedLocation) {
      setIdlocation(selectedLocation.idlocation);
      // Aseta locationin arvo takaisin input-kenttään
      setLocationValue(selectedLocation.location);
    } else {
      setIdlocation(value);
      // Jos syötetty arvoa ei löydy, aseta se takaisin input-kenttään
      setLocationValue(value);
    }
  };

  const [locationValue, setLocationValue] = useState<string>("");

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedImages = Array.from(e.target.files);

      if (images.length + selectedImages.length > 6) {
        alert("You can only upload a maximum of 6 images.");
        return;
      }

      // Päivitä esikatselukuvat
      const updatedImagePreviews: string[] = []; // Tänne kerätään kaikki esikatselukuvat

      selectedImages.forEach((image) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (typeof reader.result === "string") {
            updatedImagePreviews.push(reader.result);
            setImagePreviews([...imagePreviews, ...updatedImagePreviews]);
          }
        };
        reader.readAsDataURL(image);
      });

      // Lisää kuvat tilaan
      setImages([...images, ...selectedImages]);
    }
  };

  const handleRemoveImage = (index: number) => {
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);

    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Hae userId localStoragesta
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    if (!userId || !token) {
      alert("User ID or token not found in localStorage");
      return;
    }

    // Luo FormData-objekti ja lisää siihen kaikki lomakkeen tiedot
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("itemTitle", itemTitle);
    formData.append("itemDescription", itemDescription);
    if (price === "") {
      formData.append("price", "0");
    } else {
      formData.append("price", price);
    }
    formData.append("subcategory", subcategory);
    formData.append("itemCondition", itemCondition);
    formData.append("sellBuy", sellBuy);
    formData.append("idlocation", idlocation);

    // Lisää kuvat FormData-objektiin
    images.forEach((image) => {
      formData.append("images", image);
    });

    // Lähetä FormData backendiin POST-pyynnöllä
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/pushitem`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Tärkeää asettaa oikea content type
        },
      })
      .then((response) => {
        // Tarkista vastauksesta tarvittavat tiedot
        const { success, itemId } = response.data;

        if (success) {
          alert(
            "Item successfully listed.\nYour item notice is valid for 6 months."
          );
          navigate("/myitems");
        } else {
          throw new Error("Item listing failed.");
        }
      })
      .catch((error) => {
        console.error("Error in data transmission:", error);
        alert("Error in product transmission.");
      });
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    value = value.replace(",", ".");
    if (value.length > 11) {
      return;
    }
    // Tarkista, että arvo on DECIMAL(11,2) -muotoinen
    const isValidPrice = /^\d{1,11}(\.\d{1,2})?$/.test(value);
    setPrice(value);
    setPriceValid(isValidPrice || value === ""); // Sallitaan myös tyhjä arvo
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Helmet>
        <title>New Item - Pirpana</title>
        <meta
          name="description"
          content="Add a new item to your listings on Pirpana. Provide details such as title, description, price, and images to showcase your item."
        />
        <meta name="keywords" content="add new item, list product, Pirpana" />
      </Helmet>
      <div className="new-item-container m-3 w-100">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginBottom: 20, marginTop: -10 }}
        >
          <h2 className="title">New Item</h2>
          <img
            src={newitembox}
            loading="lazy"
            alt="Background"
            style={{
              width: 60,
              height: 60,
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        <p
          style={{
            fontSize: 9,
            margin: 0,
          }}
        >
          Fields marked with an asterisk * are mandatory
        </p>
        <form
          onSubmit={handleSubmit}
          className="new-item-form"
          encType="multipart/form-data"
        >
          <div className="form-group">
            <label htmlFor="itemTitle">Item Title *</label>
            <input
              type="text"
              id="itemTitle"
              value={itemTitle}
              onChange={(e) => setItemTitle(e.target.value)}
              required
              maxLength={99}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="itemDescription">Item Description</label>
            <textarea
              id="itemDescription"
              value={itemDescription}
              onChange={(e) => setItemDescription(e.target.value)}
              rows={4}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="category">Category *</label>
            <select
              id="category"
              value={category}
              onChange={handleCategoryChange}
              required
              className="form-control"
            >
              <option value="">select category</option>
              {categoriesWithSubcategories.map((categoryWithSubcategories) => (
                <option
                  key={categoryWithSubcategories.category}
                  value={categoryWithSubcategories.category}
                >
                  {categoryWithSubcategories.category}
                </option>
              ))}
            </select>
          </div>
          {category && (
            <div className="form-group">
              <label htmlFor="subcategory">Subcategory *</label>
              <select
                id="subcategory"
                value={subcategory}
                onChange={(e) => setSubcategory(e.target.value)}
                required
                className="form-control"
              >
                <option value="">select subcategory</option>
                {categoriesWithSubcategories
                  .find((cat) => cat.category === category)
                  ?.subcategories.map((subcategory: Subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
              </select>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="itemCondition">Item Condition</label>
            <select
              id="itemCondition"
              value={itemCondition}
              onChange={(e) => setItemCondition(e.target.value)}
              className="form-control"
            >
              <option value="">select condition (optional)</option>
              <option value="new">New</option>
              <option value="used">Used</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="location">Location *</label>
            <input
              id="location"
              type="text"
              value={locationValue}
              onChange={handleLocationChange}
              required
              className="form-control"
              list="locationsList"
            />
            <datalist id="locationsList">
              {locations.map((loc, index) => (
                <option key={index} value={loc.location} />
              ))}
            </datalist>
          </div>
          <div className="d-flex justify-content-between">
            <div className="form-group" style={{ width: 130 }}>
              <label htmlFor="price">Price PHP</label>
              <input
                type="text"
                id="price"
                value={price}
                onChange={handlePriceChange}
                className={`form-control ${!priceValid ? "is-invalid" : ""}`}
              />
              {!priceValid && (
                <div className="invalid-feedback">
                  Price must be a valid decimal number (up to 2 decimal places).
                </div>
              )}
            </div>
            <div className="form-group" style={{ width: 130 }}>
              <label htmlFor="sellBuy">Sell or Buy *</label>
              <select
                id="sellBuy"
                value={sellBuy}
                onChange={(e) => setSellBuy(e.target.value)}
                className="form-control"
                required
              >
                <option value="sell">I am selling</option>
                <option value="buy">I am buying</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="images">Images (max 6)</label>
            <input
              type="file"
              id="images"
              onChange={handleImageChange}
              multiple
              accept="image/*"
              className="form-control"
            />
          </div>
          {imagePreviews.length > 0 && (
            <div className="form-group">
              <label>Selected images</label>
              <div className="d-flex flex-wrap">
                {imagePreviews.map((preview, index) => (
                  <div key={index} className="position-relative m-2">
                    <img
                      src={preview}
                      loading="lazy"
                      alt={`Preview ${index + 1}`}
                      className="img-thumbnail"
                      style={{ maxWidth: 100, maxHeight: 100 }}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-danger position-absolute top-0 end-0"
                      onClick={() => handleRemoveImage(index)}
                      style={{ borderRadius: "50%" }}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <p></p>
          <button
            type="submit"
            className="btn-submit"
            style={{ backgroundColor: "rgb(13, 119, 108)" }}
          >
            Submit
          </button>
        </form>
        {/* Muut sisältökuvat */}
        <img
          src={bgimage}
          loading="lazy"
          alt="Background"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default NewItemFile;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { JwtPayload, jwtDecode } from "jwt-decode";

interface CustomJwtPayload extends JwtPayload {
  email?: string;
}

const Unsubscribe = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [tokenFound, setTokenFound] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode<CustomJwtPayload>(token);
        if (decodedToken && decodedToken.email) {
          setEmail(decodedToken.email);
          setTokenFound(true);
        }
      } catch (error) {
        console.error("Invalid token:", error);
        setMessage("Invalid token");
      }
    } else {
      setMessage("Please enter your email to unsubscribe");
    }
  }, []);

  const handleUnsubscribe = async () => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setMessage("Please enter a valid email address");
      return;
    }

    try {
      await axios.post(
        `${
          process.env.REACT_APP_SERVER
        }/api/unsubscribe-newsletter?email=${encodeURIComponent(email)}`
      );
      setMessage(
        "SUCCESSFULLY UNSUBSCRIBED from the newsletter. You will be redirected to the homepage."
      );
      setIsUnsubscribed(true);
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } catch (error) {
      setMessage("Failed to unsubscribe. Please try again later");
    }
  };

  const handleHeaderClick = () => {
    navigate("/");
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(219, 255, 251)",
        position: "fixed",
        zIndex: 9999,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <div>
        <span
          onClick={handleHeaderClick}
          style={{
            fontSize: "2rem",
            textAlign: "center",
            display: "block",
            cursor: "pointer",
          }}
        >
          Pirpana
        </span>
        <p
          style={{
            margin: "2rem",
            fontSize: "3rem",
            textAlign: "center",
            display: "block",
          }}
        >
          ✉️
        </p>
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            width: "300px",
          }}
        >
          <h1>Unsubscribe from Newsletter</h1>
          <p>Enter your email to unsubscribe:</p>
          <input
            className="w-100 mb-1"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            disabled={isUnsubscribed}
          />
          <p
            style={{
              backgroundColor: isUnsubscribed ? "lightgreen" : "#FFD700",
              padding: "0px",
              borderRadius: "5px",
            }}
          >
            {message}
          </p>
          <button
            type="button"
            className="btn-submit w-100 mt-5 mb-3"
            style={{ backgroundColor: "rgb(13, 119, 108)" }}
            onClick={handleUnsubscribe}
            disabled={isUnsubscribed}
          >
            Unsubscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;

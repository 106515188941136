import React, { useState, ChangeEvent, FormEvent } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import validation from "./SignupValidation";
import axios from "axios";

interface FormValues {
  username: string;
  email: string;
  password: string;
}

const Signup: React.FC = () => {
  const [values, setValues] = useState<FormValues>({
    username: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const server = process.env.REACT_APP_SERVER;

  const navigate = useNavigate();

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const validationErrors = validation(values);
    setErrors(validationErrors);

    // Jos ei ole virheitä, voit jatkaa lomakkeen käsittelyä tässä
    if (Object.keys(validationErrors).length === 0) {
      // lähetä lomakkeen tiedot palvelimelle
      axios
        .post(`${server}/api/signup`, values)
        .then((res) => {
          console.log(res);

          // Kirjaudu sisään automaattisesti käyttäen login-reittiä
          axios
            .post(`${server}/api/login`, values)
            .then((loginRes) => {
              const { token, refreshToken, userId, isVerified } = loginRes.data;
              const tokenExpiry = Date.now() + 4 * 60 * 60 * 1000; // 4 tuntia millisekunteina

              localStorage.setItem("token", token);
              localStorage.setItem("refreshToken", refreshToken);
              localStorage.setItem("tokenExpiry", tokenExpiry.toString());
              localStorage.setItem("userId", userId);
              localStorage.setItem("isVerified", isVerified.toString());

              console.log(loginRes);
              // päivittää sivun
              window.location.reload();
              // Navigoi "/profile"-polulle onnistuneen kirjautumisen jälkeen
              navigate("/profile");
            })
            .catch((loginErr) => console.log(loginErr));
        })
        .catch((err) => {
          if (err.response && err.response.status === 409) {
            // Konflikti: sähköposti on jo käytössä
            alert("Email is already in use. Please use a different email.");
          } else {
            // Muut virheet
            console.log(err.response ? err.response.data : err.message);
            alert(
              "An error occurred while processing your request.\nPlease make sure the provided email is correct and is not already in use."
            );
          }
        });
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-start vh-100 pt-5">
      <Helmet>
        <title>Sign Up - Pirpana</title>
        <meta
          name="description"
          content="Sign up for Pirpana to create an account and start exploring unique products and services."
        />
        <meta name="keywords" content="sign up, create account, Pirpana" />
      </Helmet>
      <div className="bg-white m-3 rounded  border shadow p-4">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <p className="d-flex align-items-center justify-content-center">
              New Account
            </p>
            <label htmlFor="username">
              <strong>Username</strong>
            </label>
            <input
              type="text"
              placeholder="Enter Username"
              name="username"
              value={values.username}
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.username && (
              <p className="text-danger">{errors.username}</p>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="email">
              <strong>Email</strong>
            </label>
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              value={values.email}
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>
          <div className="mb-3">
            <label htmlFor="password">
              <strong>Password</strong>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              value={values.password}
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.password && (
              <p className="text-danger">{errors.password}</p>
            )}
          </div>
          <button type="submit" className="btn btn-success w-100">
            Sign up
          </button>
          <p>You agree to our terms and policies</p>
          <Link
            to="/login"
            className="btn btn-default border w-100 text-decoration-none"
          >
            Login
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Signup;

import React, { FormEvent, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import bgimage from "./images/newitemspic.png";
import closeButton from "./images/closebutton.png";
import newitembox from "./images/editpen.jpg";
import itemscollection from "./images/itemscollection.jpg";
import "./App.css";

axios.defaults.baseURL = `${process.env.REACT_APP_SERVER}`;

interface EditItemParams {
  iditems?: string;
  [key: string]: string | undefined;
}

interface Subcategory {
  id: string;
  name: string;
}

interface CategoryWithSubcategories {
  category: string;
  subcategories: Subcategory[];
}

const EditItem: React.FC = () => {
  const { iditems } = useParams<EditItemParams>();
  const [itemClosed, setItemClosed] = useState("");
  const [itemTitle, setItemTitle] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [price, setPrice] = useState("");
  const [priceValid, setPriceValid] = useState(true);
  const [category, setCategory] = useState<string>("");
  const [subcategory, setSubcategory] = useState<string>("");
  const [subcategoryname, setSubcategoryName] = useState<string>("");
  const [itemCondition, setItemCondition] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [sellBuy, setSellBuy] = useState<string>("");
  const [itemImagePaths, setItemImagePaths] = useState<string[]>([]);
  const [removedImagePaths, setRemovedImagePaths] = useState<string[]>([]);
  const [locations, setLocations] = useState<
    { location: string; idlocation: string }[]
  >([]);
  const [idlocation, setIdlocation] = useState<string>("");
  const [categoriesWithSubcategories, setCategoriesWithSubcategories] =
    useState<CategoryWithSubcategories[]>([]);

  const lastFetchTimeRefCategories = useRef<number | null>(null);
  const lastFetchTimeRefLocations = useRef<number | null>(null);
  const lastFetchTimeRefDetails = useRef<number | null>(null);
  const lastFetchTimeRefImages = useRef<number | null>(null);
  const FETCH_INTERVAL = 1000; // 1 second

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const now = Date.now();
      if (
        !lastFetchTimeRefCategories.current ||
        now - lastFetchTimeRefCategories.current > FETCH_INTERVAL
      ) {
        lastFetchTimeRefCategories.current = now;
        try {
          const response = await axios.get("/api/get-categories");
          setCategoriesWithSubcategories(response.data);
        } catch (error) {
          console.error("Error fetching categories and subcategories:", error);
        }
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      const now = Date.now();
      if (
        !lastFetchTimeRefLocations.current ||
        now - lastFetchTimeRefLocations.current > FETCH_INTERVAL
      ) {
        lastFetchTimeRefLocations.current = now;
        try {
          const response = await axios.get("/api/get-locations");
          setLocations(response.data);
        } catch (error) {
          console.error("Error fetching locations:", error);
        }
      }
    };

    fetchLocations();
  }, []);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
    setSubcategory("");
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const oldImagesNro = itemImagePaths.length;
      const selectedImages = Array.from(e.target.files);

      if (images.length + selectedImages.length > 6 - oldImagesNro) {
        alert("You can only upload a maximum of 6 images.");
        return;
      }

      // Päivitä esikatselukuvat
      const updatedImagePreviews: string[] = []; // Tänne kerätään kaikki esikatselukuvat

      selectedImages.forEach((image) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (typeof reader.result === "string") {
            updatedImagePreviews.push(reader.result);
            setImagePreviews([...imagePreviews, ...updatedImagePreviews]);
          }
        };
        reader.readAsDataURL(image);
      });

      setImages([...images, ...selectedImages]);
    }
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    // Etsi syötetty arvo locations-taulukosta
    const selectedLocation = locations.find((loc) => loc.location === value);

    if (selectedLocation) {
      setIdlocation(selectedLocation.idlocation);
      // Aseta locationin arvo takaisin input-kenttään
      setLocationValue(selectedLocation.location);
    } else {
      setIdlocation(value);
      // Jos syötetty arvoa ei löydy, aseta se takaisin input-kenttään
      setLocationValue(value);
    }
  };
  const [locationValue, setLocationValue] = useState<string>("");

  const handleRemoveImageUrl = (index: number) => {
    const updatedPaths = [...itemImagePaths];
    const removedPath = updatedPaths.splice(index, 1);
    setItemImagePaths(updatedPaths);
    setRemovedImagePaths([...removedImagePaths, ...removedPath]);
  };

  const handleRemoveImage = (index: number) => {
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);

    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Hae userId localStoragesta
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    if (!userId || !token) {
      alert("User ID or token not found in localStorage");
      return;
    }

    try {
      // Lähetä removedImagePaths palvelimelle poistettavaksi
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/remove-images`,
        { removedImagePaths }
      );

      setRemovedImagePaths([]);
    } catch (error) {
      console.error("Error removing images:", error);
      // Käsittele virheellinen tilanne tarpeen mukaan
    }

    // Luo FormData-objekti ja lisää siihen kaikki lomakkeen tiedot
    const formData = new FormData();
    formData.append("iditems", iditems || "");
    formData.append("itemTitle", itemTitle);
    formData.append("itemDescription", itemDescription);
    if (price === "") {
      formData.append("price", "0");
    } else {
      formData.append("price", price);
    }
    formData.append("subcategory", subcategory);
    formData.append("itemCondition", itemCondition);
    formData.append("sellBuy", sellBuy);
    formData.append("idlocation", idlocation);

    // Lisää kuvat FormData-objektiin
    images.forEach((image) => {
      formData.append("images", image);
    });

    // Lähetä FormData backendiin POST-pyynnöllä
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/updateitem`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Tärkeää asettaa oikea content type
        },
      })
      .then((response) => {
        // Tarkista vastauksesta tarvittavat tiedot
        const { success, itemId } = response.data;

        if (success) {
          alert("Item successfully update");
          navigate("/myitems");
        } else {
          throw new Error("Item sending failed.");
        }
      })
      .catch((error) => {
        console.error("Error in data transmission:", error);
        alert("Error in item transmission.");
      });
  };

  useEffect(() => {
    if (iditems) {
      const fetchItemDetails = async () => {
        const now = Date.now();
        if (
          !lastFetchTimeRefDetails.current ||
          now - lastFetchTimeRefDetails.current > FETCH_INTERVAL
        ) {
          lastFetchTimeRefDetails.current = now;
          const token = localStorage.getItem("token");
          if (token) {
            try {
              const response = await axios.get(`/api/getitem/${iditems}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              const {
                closed,
                itemtitle,
                itemdescription,
                price,
                subcategories_idsubcategories,
                subcategoryname,
                itemcondition,
                sellbuy,
                idlocation,
                location_info,
              } = response.data;
              setItemClosed(closed);
              setItemTitle(itemtitle);
              setItemDescription(itemdescription);
              setPrice(price.toString());
              setSubcategory(subcategories_idsubcategories);
              setSubcategoryName(subcategoryname);
              setItemCondition(itemcondition);
              setSellBuy(sellbuy);
              setIdlocation(idlocation);
              setLocationValue(location_info);
            } catch (error) {
              console.error("Error fetching item details:", error);
            }
          }
        }
      };

      const fetchItemImages = async () => {
        const now = Date.now();
        if (
          !lastFetchTimeRefImages.current ||
          now - lastFetchTimeRefImages.current > FETCH_INTERVAL
        ) {
          lastFetchTimeRefImages.current = now;
          const token = localStorage.getItem("token");
          if (token) {
            try {
              const response = await axios.get(`/api/itemimages/${iditems}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              setItemImagePaths(response.data);
            } catch (error) {
              console.error("Error fetching item images:", error);
            }
          }
        }
      };

      fetchItemDetails();
      fetchItemImages();

      // Remove token parameter from URL to prevent it from being visible
      window.history.replaceState({}, document.title, "/myitems");
    }
  }, [iditems]);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    value = value.replace(",", ".");
    if (value.length > 11) {
      return;
    }
    // Tarkista, että arvo on DECIMAL(11,2) -muotoinen
    const isValidPrice = /^\d{1,11}(\.\d{1,2})?$/.test(value);
    setPrice(value);
    setPriceValid(isValidPrice || value === ""); // Sallitaan myös tyhjä arvo

    alert(idlocation);
  };

  const handleDeleteItem = async () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete the item?"
    );
    if (!userConfirmed) {
      return;
    }

    const currentRemovedImagePaths: string[] =
      [...itemImagePaths, ...removedImagePaths] || [];

    try {
      const response = await axios.post("/api/delete-item", { iditems });
      if (response.status === 200) {
        navigate("/myitems");
      }
    } catch (error) {
      console.error("Failed to delete item:", error);
      alert("Failed to delete item");
    }

    // kuvien poisto palvelimelta ja tietokannasta
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/remove-images`,
        { removedImagePaths: currentRemovedImagePaths }
      );
    } catch (error) {
      console.error("Error removing images:", error);
    }
  };

  const handleCloseItemClick = async () => {
    const userConfirmed = window.confirm(
      "If you close an item, it will no longer appear in searches. Also, any uploaded images will be deleted. You can make a closed item visible again if needed. If you wish to completely remove an item from your account, click the 'Delete item' button.\n\nAre you sure you want to close the item?"
    );
    if (!userConfirmed) {
      return;
    }

    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    const currentRemovedImagePaths: string[] =
      [...itemImagePaths, ...removedImagePaths] || [];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/close-item`,
        { iditems },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error closing the item:", error);
    }

    // kuvien poisto palvelimelta ja tietokannasta
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/remove-images`,
        { removedImagePaths: currentRemovedImagePaths }
      );
    } catch (error) {
      console.error("Error removing images:", error);
    }
    navigate("/myitems");
  };

  const handleReactivateItemClick = async () => {
    const userConfirmed = window.confirm(
      "Reactivate the item makes it visible again in searches.\n\nAre you sure you want to reactivate the item?"
    );
    if (!userConfirmed) {
      return;
    }

    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/reactivate-item`,
        { iditems },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data && response.data.message) {
          alert(response.data.message);
        }
      }
      setItemClosed("");
    } catch (error) {
      console.error("Error returning the item:", error);
      alert("Failed to reactivate the item");
    }
  };

  const formatDate = (dateString: string) => {
    if (!dateString) {
      return "";
    }
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const navigateMyItems = () => {
    navigate("/myitems");
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Helmet>
        <title>Edit Your Listing - Pirpana</title>
        <meta
          name="description"
          content="Edit the details of your listed product or item on Pirpana. Update the title, description, price, and more."
        />
        <meta
          name="keywords"
          content="edit listing, edit item, edit information, update product, update product, Pirpana"
        />
      </Helmet>
      <div className="new-item-container m-3 pt-0 w-100">
        <button
          onClick={() => navigateMyItems()}
          className="mb-2 mt-0"
          style={{
            backgroundColor: "transparent",
            border: "none",
            fontSize: "30px",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginTop: -30 }}
        >
          <h2 className="mt-4 title">Edit Item</h2>
          <div>
            <img
              src={newitembox}
              loading="lazy"
              alt="Background"
              style={{
                width: 60,
                height: 60,
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            <p className="text-center m-0 small">id {iditems}</p>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p
            style={{
              fontSize: 9,
              margin: 0,
            }}
          >
            Fields marked with an asterisk * are mandatory
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className="new-item-form"
          encType="multipart/form-data"
        >
          <div className="form-group">
            <label htmlFor="itemTitle">Item Title *</label>
            <input
              type="text"
              id="itemTitle"
              value={itemTitle}
              onChange={(e) => setItemTitle(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="itemDescription">Item Description</label>
            <textarea
              id="itemDescription"
              value={itemDescription}
              onChange={(e) => setItemDescription(e.target.value)}
              rows={4}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="category">Category *</label>
            <select
              id="category"
              value={category}
              onChange={handleCategoryChange}
              className="form-control"
            >
              <option value="">{subcategoryname}</option>
              {categoriesWithSubcategories.map((categoryWithSubcategories) => (
                <option
                  key={categoryWithSubcategories.category}
                  value={categoryWithSubcategories.category}
                >
                  {categoryWithSubcategories.category}
                </option>
              ))}
            </select>
          </div>
          {category && (
            <div className="form-group">
              <label htmlFor="subcategory">Subcategory *</label>
              <select
                id="subcategory"
                value={subcategory}
                onChange={(e) => setSubcategory(e.target.value)}
                className="form-control"
                required
              >
                <option value="">{subcategoryname}</option>
                {categoriesWithSubcategories
                  .find((cat) => cat.category === category)
                  ?.subcategories.map((subcategory: Subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
              </select>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="itemCondition">Item Condition</label>
            <select
              id="itemCondition"
              value={itemCondition}
              onChange={(e) => setItemCondition(e.target.value)}
              className="form-control"
            >
              <option value="">select condition (optional)</option>
              <option value="new">New</option>
              <option value="used">Used</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="location">Location *</label>
            <input
              id="location"
              type="text"
              value={locationValue}
              onChange={handleLocationChange}
              required
              className="form-control"
              list="locationsList"
            />
            <datalist id="locationsList">
              {locations.map((loc, index) => (
                <option key={index} value={loc.location} />
              ))}
            </datalist>
          </div>
          <div className="d-flex justify-content-between">
            <div className="form-group" style={{ width: 130 }}>
              <label htmlFor="price">Price PHP</label>
              <input
                type="text"
                id="price"
                value={price}
                onChange={handlePriceChange}
                className={`form-control ${!priceValid ? "is-invalid" : ""}`}
              />
              {!priceValid && (
                <div className="invalid-feedback">
                  Price must be a valid decimal number (up to 2 decimal places).
                </div>
              )}
            </div>
            <div className="form-group" style={{ width: 130 }}>
              <label htmlFor="sellBuy">Sell or Buy *</label>
              <select
                id="sellBuy"
                value={sellBuy}
                onChange={(e) => setSellBuy(e.target.value)}
                className="form-control"
                required
              >
                <option value="sell">I am selling</option>
                <option value="buy">I am buying</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="images">Images (max 6)</label>
            <input
              type="file"
              id="images"
              onChange={handleImageChange}
              multiple
              accept="image/*"
              className="form-control"
            />
          </div>
          {imagePreviews.length > 0 && (
            <div className="form-group">
              <label>Selected new images</label>
              <div className="d-flex flex-wrap">
                {imagePreviews.map((preview, index) => (
                  <div key={index} className="position-relative m-2">
                    <img
                      src={preview}
                      loading="lazy"
                      alt={`Preview ${index + 1}`}
                      className="img-thumbnail"
                      style={{ maxWidth: 100, maxHeight: 100 }}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-danger position-absolute top-0 end-0"
                      onClick={() => handleRemoveImage(index)}
                      style={{ borderRadius: "50%" }}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {itemImagePaths.length > 0 && (
            <div className="form-group">
              <label>Current Images</label>
              <div className="d-flex flex-wrap">
                {itemImagePaths.map((imagePath, index) => (
                  <div key={index} className="position-relative m-2">
                    <img
                      src={`${process.env.REACT_APP_SERVER}/server/${imagePath}`}
                      loading="lazy"
                      alt={`Item Image ${index + 1}`}
                      className="thumbnail"
                      style={{ maxWidth: 100, maxHeight: 100 }}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-danger position-absolute top-0 end-0"
                      onClick={() => handleRemoveImageUrl(index)}
                      style={{ borderRadius: "50%" }}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <p></p>
          <button
            type="submit"
            className="btn-submit mb-4"
            style={{ backgroundColor: "rgb(13, 119, 108)" }}
          >
            Save changes
          </button>
          <img
            src={itemscollection}
            loading="lazy"
            alt="Background"
            style={{
              width: "100%",
              height: 60,
              objectFit: "cover",
            }}
          />
          <button
            id="ReactivateItem"
            type="button"
            className="btn btn-warning mt-1"
            style={{
              display: itemClosed ? "inline-block" : "none",
            }}
            onClick={handleReactivateItemClick}
          >
            <p className="m-0">item closed: {formatDate(itemClosed)}</p>{" "}
            ReActivate item
          </button>
          <button
            id="setClosed"
            type="button"
            className="btn btn-warning mt-1"
            style={{
              display: itemClosed ? "none" : "inline-block",
            }}
            onClick={handleCloseItemClick}
          >
            Business done / set closed
          </button>
          <button
            type="button"
            className="btn-submit mt-3"
            style={{
              backgroundColor: "gray",
            }}
            onClick={handleDeleteItem}
          >
            Delete item
          </button>
          <button
            type="submit"
            className="btn-submit mt-3"
            style={{
              backgroundColor: "gray",
            }}
            onClick={() => navigate("/myitems")}
          >
            Cancel
          </button>
        </form>
        <img
          src={bgimage}
          loading="lazy"
          alt="Background"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

function setUserInfo(userInfo: {
  idusers: any;
  password: any;
  username: any;
  email: any;
  country: any;
  city: any;
  registrationdate: any;
  accountdeleted: any;
  profilepictureurl: any;
  isverified: any;
}) {
  // Tässä voit toteuttaa tarvittavat toimenpiteet käyttäjätietojen asettamiseksi
  console.log("Setting user info:", userInfo);
  // Esimerkiksi voit tallentaa tiedot tilaan, jos tarpeen
}

export default EditItem;

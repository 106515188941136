import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./App.css";
import itemscollection3 from "./images/itemscollection3.jpg";

interface UserItem {
  iditems: number;
  itemtitle: string;
  itemdescription: string;
  price: number;
  closed: string;
  subcategory: string;
  dateadded: string;
  itemcondition: string;
  sellbuy: string;
  lastedited: string;
  location_info: string;
  imagePaths: string[];
}

const FETCH_INTERVAL = 1000; // 1 second

const MyItemsfile: React.FC = () => {
  const [userItems, setUserItems] = useState<UserItem[]>([]);
  const navigate = useNavigate();
  const [activeImage, setActiveImage] = useState<string | null>(null);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  const lastFetchTimeRefItems = useRef<number | null>(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    const fetchUserItems = async () => {
      const now = Date.now();

      // Throttle: Only fetch data if more than FETCH_INTERVAL has passed
      if (
        !lastFetchTimeRefItems.current ||
        now - lastFetchTimeRefItems.current > FETCH_INTERVAL
      ) {
        lastFetchTimeRefItems.current = now;

        try {
          const userId = localStorage.getItem("userId");

          if (!userId || !token) {
            throw new Error("User ID or token not found in localStorage");
          }

          // Lähetä pyyntö backendiin hakeaksesi käyttäjän tuotteet
          const itemsResponse = await axios.get(
            `${process.env.REACT_APP_SERVER}/api/myitems`,
            {
              params: { userId },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const items: UserItem[] = itemsResponse.data;

          // Hae kaikkien tuotteiden kuvat yhdellä kyselyllä
          const itemIds = items.map((item) => item.iditems);
          const imagesResponse = await axios.post(
            `${process.env.REACT_APP_SERVER}/api/itemimages-array`,
            { itemIds },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const imagesByItem: { [key: number]: string[] } = imagesResponse.data;

          // Liitä kuvat tuotteisiin
          const itemsWithImages = items.map((item) => ({
            ...item,
            imagePaths: imagesByItem[item.iditems] || [],
          }));

          // Järjestä tuotteet
          const sortedItems = itemsWithImages.sort(
            (a: UserItem, b: UserItem) => {
              if (!a.closed && !b.closed) {
                return (
                  new Date(b.dateadded).getTime() -
                  new Date(a.dateadded).getTime()
                );
              } else if (!a.closed && b.closed) {
                return -1;
              } else if (a.closed && !b.closed) {
                return 1;
              } else {
                return (
                  new Date(b.closed).getTime() - new Date(a.closed).getTime()
                );
              }
            }
          );

          setUserItems(sortedItems);
        } catch (error) {
          console.error("Error fetching user items:", error);
        }
      }
    };

    fetchUserItems();
  }, [navigate]);

  const formatDate = (dateString: string) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const toggleImageSize = (imagePath: string) => {
    setActiveImage(activeImage === imagePath ? null : imagePath);
  };

  const toggleItemDetails = (itemId: number) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(itemId) ? [] : [itemId]
    );
  };

  const handleEditItemClick = (itemId: number) => {
    navigate(`/edititem/${itemId}`);
  };

  return (
    <div className="container">
      <Helmet>
        <title>My Items - Pirpana</title>
        <meta
          name="description"
          content="Manage and view the items and products you have listed on Pirpana. Edit, remove, or review your active listings."
        />
        <meta
          name="keywords"
          content="my items, my products, manage listings, Pirpana"
        />
      </Helmet>
      <h2 className="m-3 text-center title">My Items</h2>
      <div className="row">
        {userItems.map((item) => (
          <div key={item.iditems} className="col-md-12 mb-2">
            <div className="card">
              <div className="card-body shadow">
                <div
                  className="mainshow d-flex justify-content-between"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleItemDetails(item.iditems)}
                >
                  <h3 className="card-title">{item.itemtitle}</h3>
                  <p className="card-text text-right">
                    <strong>{item.price}</strong> PHP
                  </p>
                </div>
                <div className="mainshow d-flex justify-content-between">
                  <p className="card-text m-0">
                    <strong>Added:</strong> {formatDate(item.dateadded)}
                  </p>
                  <p className="card-text text-right">item id {item.iditems}</p>
                </div>
                <p
                  className="card-text"
                  style={{ color: item.closed ? "inherit" : "green" }}
                >
                  {item.closed ? (
                    `Closed: ${formatDate(item.closed)}`
                  ) : (
                    <strong>Active</strong>
                  )}
                </p>
                {expandedItems.includes(item.iditems) && (
                  <>
                    <p className="card-text">{item.itemdescription}</p>
                    <p className="card-text">
                      <strong>Category:</strong> {item.subcategory}
                    </p>
                    {item.itemcondition && (
                      <p className="card-text">
                        <strong>Item Condition:</strong> {item.itemcondition}
                      </p>
                    )}
                    <p className="card-text">
                      <strong>Sell/Buy:</strong> {item.sellbuy}
                    </p>
                    <p className="card-text">
                      <strong>Location:</strong> {item.location_info}
                    </p>
                    <div className="m-1" style={{ width: "fit-content" }}>
                      <div className="card-img d-flex">
                        {item.imagePaths && item.imagePaths.length > 0 ? (
                          <>
                            {item.imagePaths.map((imagePath, index) => (
                              <div
                                key={index}
                                className={`image-container ${
                                  activeImage === imagePath ? "active" : ""
                                }`}
                                onClick={() => toggleImageSize(imagePath)}
                              >
                                <img
                                  src={`${process.env.REACT_APP_SERVER}/server/${imagePath}`}
                                  loading="lazy"
                                  alt={`Item Image ${index + 1}`}
                                  className="fluid"
                                  style={{
                                    maxHeight:
                                      activeImage === imagePath
                                        ? "100%"
                                        : "100px",
                                    padding: "2px",
                                  }}
                                />
                              </div>
                            ))}
                          </>
                        ) : (
                          <p>No images</p>
                        )}
                      </div>
                    </div>
                    <p></p>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="card-text m-0">
                        <strong>Last edit:</strong>{" "}
                        {formatDate(item.lastedited)}{" "}
                      </p>
                      <button
                        className="btn btn-sm btn-primary ml-2"
                        onClick={() => handleEditItemClick(item.iditems)}
                        style={{
                          backgroundColor: "rgb(13, 119, 108)",
                          border: 0,
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <p className="text-center">_________________________________________</p>
      <p>
        Your item cards are valid for six months, after which they will be
        removed regardless of whether they are active or not. If you want to
        renew your item listing, you need to go to the "New Item" page and
        create a new listing.
      </p>
      <p className="text-center">_________________________________________</p>
      <img className="w-100 mb-4" src={itemscollection3} alt="items" />
    </div>
  );
};

export default MyItemsfile;

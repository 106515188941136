import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import closeButton from "./images/closebutton.png";
import closeditem from "./images/closeditem.png";

axios.defaults.baseURL = process.env.REACT_APP_SERVER;

interface ItemData {
  closed: string;
  deleted: string;
  iditems: string;
  itemtitle: string;
  price: string;
  sellbuy: string;
  itemdescription: string;
  location_idlocation: string;
  location_info: string;
  dateadded: string;
  lastedited: string;
  itemcondition: string;
  subcategories_idsubcategories: string;
  subcategory: string;
  users_idusers: string;
  username: string;
  imagePaths: string[];
}

const FETCH_INTERVAL = 1000; // 1 second

const ItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [itemClosed, setItemClosed] = useState<string>("");
  const [itemDeleted, setItemDeleted] = useState<string>("");
  const [itemHolderID, setItemHolderID] = useState<string>("");
  const [itemHolderName, setItemHolderName] = useState<string>("");
  const [itemTitle, setItemTitle] = useState<string>("");
  const [isLoggoedIn, setIsLoggedIn] = useState<boolean>(false);
  const [itemDescription, setItemDescription] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [subcategoryID, setSubcategoryID] = useState<string>("");
  const [subcategoryNAME, setSubcategoryNAME] = useState<string>("");
  const [itemCondition, setItemCondition] = useState<string>("");
  const [sellBuy, setSellBuy] = useState<string>("");
  const [idlocation, setIdlocation] = useState<number>(0);
  const [locationInfo, setLocationInfo] = useState<string>("");
  const [imagePaths, setImagePaths] = useState<string[]>([]);
  const [dateAdded, setDateAdded] = useState<string>("");
  const [lastEdited, setLastEdited] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [itemData, setItemData] = useState<ItemData | null>(null);

  const lastFetchTimeRefDetails = useRef<number | null>(null);
  const lastFetchTimeRefImages = useRef<number | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      const fetchItemDetails = async () => {
        const now = Date.now();
        if (
          !lastFetchTimeRefDetails.current ||
          now - lastFetchTimeRefDetails.current > FETCH_INTERVAL
        ) {
          lastFetchTimeRefDetails.current = now;

          try {
            const response = await axios.get(`/api/getitem/${id}`);
            const {
              closed,
              deleted,
              holder_idusers,
              holder_name,
              itemtitle,
              itemdescription,
              price,
              subcategories_idsubcategories,
              subcategoryname,
              itemcondition,
              sellbuy,
              idlocation,
              location_info,
              dateadded,
              lastedited,
            } = response.data;
            setItemClosed(closed);
            setItemDeleted(deleted);
            setItemHolderID(holder_idusers);
            setItemHolderName(holder_name);
            setItemTitle(itemtitle);
            setItemDescription(itemdescription);
            setPrice(price.toString());
            setSubcategoryID(subcategories_idsubcategories);
            setSubcategoryNAME(subcategoryname);
            setItemCondition(itemcondition);
            setSellBuy(sellbuy);
            setIdlocation(idlocation);
            setLocationInfo(location_info);
            setDateAdded(dateadded);
            setLastEdited(lastedited);

            setItemData((prevData: any) => ({
              ...prevData,
              closed: closed,
              deleted: deleted,
              iditems: id,
              itemtitle: itemtitle,
              price: price.toString(),
              sellbuy: sellbuy,
              itemdescription: itemdescription,
              location_idlocation: idlocation,
              location_info: location_info,
              dateadded: dateadded,
              lastedited: lastedited,
              itemcondition: itemcondition,
              subcategories_idsubcategories: subcategories_idsubcategories,
              subcategory: subcategoryname,
              imagePaths: imagePaths,
              users_idusers: holder_idusers,
              username: holder_name,
            }));
          } catch (error) {
            console.error("Error fetching item details:", error);
            setError("Error fetching item details.");
          }
        }
      };

      const fetchItemImages = async () => {
        const now = Date.now();
        if (
          !lastFetchTimeRefImages.current ||
          now - lastFetchTimeRefImages.current > FETCH_INTERVAL
        ) {
          lastFetchTimeRefImages.current = now;

          try {
            const response = await axios.get(`/api/itemimages/${id}`);
            setItemData((prevData: any) => ({
              ...prevData,
              imagePaths: response.data || [],
            }));
          } catch (error) {
            console.error("Error fetching item images:", error);
            setError("Error fetching item images.");
          }
        }
      };

      fetchItemDetails();
      fetchItemImages();

      window.history.replaceState({}, document.title, `/`);
    }
  }, [id]);

  const handleItemViewCloseClick = () => {
    if (itemData) {
      localStorage.setItem("lastItem", JSON.stringify(itemData));
    }
    navigate("/");
  };

  const handleContactSellerClick = () => {
    const verified = localStorage.getItem("isVerified");

    if (!itemData) {
      console.error("itemData is not available");
      return;
    }

    if (id && isLoggoedIn && verified === "1") {
      localStorage.setItem("selectedItem", JSON.stringify(itemData));
      localStorage.setItem("lastItem", JSON.stringify(itemData));
      navigate("/messages");
    } else if (verified === "0") {
      localStorage.setItem("lastItem", JSON.stringify(itemData));
      alert("Please verify your email first");
      navigate("/login");
    } else {
      localStorage.setItem("lastItem", JSON.stringify(itemData));
      navigate("/login");
    }
  };

  const formatDate = (dateString: string) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!itemTitle) {
    return <div>Item not found.</div>;
  }

  if (itemClosed || itemDeleted == "1") {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          padding: "20px",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "900px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "20px",
            overflowY: "auto",
            height: "90%",
          }}
        >
          <button
            type="button"
            onClick={handleItemViewCloseClick}
            style={{
              position: "fixed",
              top: "4px",
              right: "4px",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              padding: 0,
            }}
          >
            <img
              src={closeButton}
              loading="lazy"
              alt="Close"
              style={{
                cursor: "pointer",
                width: "80px",
                height: "80px",
                marginTop: 0,
                marginRight: 0,
              }}
            />
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              textAlign: "center",
            }}
          >
            <h2>This item is closed</h2>
            <img
              src={closeditem}
              loading="lazy"
              alt="closed"
              style={{
                width: "60%",
                height: "auto",
                marginTop: "10px",
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(219, 255, 251)",
        overflowY: "scroll",
      }}
    >
      <Helmet>
        <title>{`Item - ${itemTitle} - Pirpana`}</title>
        <meta
          name="description"
          content={`View details for ${itemTitle}. Find out more about this item or product, including its description, price, and condition.`}
        />
        <meta
          name="keywords"
          content={`item details, ${itemTitle}, ${itemDescription}, ${subcategoryNAME}, ${locationInfo}, Pirpana`}
        />
      </Helmet>
      <span
        onClick={handleItemViewCloseClick}
        style={{
          fontSize: 26,
          textAlign: "center",
          display: "block",
          cursor: "pointer",
        }}
      >
        Pirpana
      </span>
      <div
        className="container"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "900px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          padding: "16px",
          overflowY: "auto",
          maxHeight: "90%",
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-4">
          <button
            type="button"
            className="btn-submit"
            style={{
              backgroundColor: "rgb(13, 119, 108)",
              borderRadius: "12px",
              color: "#fff",
              marginTop: "-10px",
              marginLeft: "-10px",
            }}
            onClick={handleContactSellerClick}
          >
            Contact Seller
          </button>
          <button
            type="button"
            onClick={handleItemViewCloseClick}
            style={{
              position: "fixed",
              top: "4px",
              right: "4px",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              padding: 0,
            }}
          >
            <img
              src={closeButton}
              loading="lazy"
              alt="Close"
              style={{
                cursor: "pointer",
                width: "60px",
                height: "60px",
                marginTop: 0,
                marginRight: 0,
              }}
            />
          </button>
        </div>
        <div className="item-details">
          <h4 className="card-title">{itemTitle}</h4>
          <p className="card-text">
            <strong>{price}</strong> PHP
          </p>
          <p className="card-text m-3">{itemDescription}</p>
          <p className="card-text m-0">
            <strong>Category:</strong> {subcategoryNAME}
          </p>
          {itemCondition && (
            <p className="card-text">
              <strong>Condition:</strong> {itemCondition}
            </p>
          )}
          <p className="card-text m-0 mt-3">
            {sellBuy === "sell" ? (
              <>
                User{" "}
                <b style={{ color: "rgb(0, 139, 139)" }}>{itemHolderName}</b> is
                selling this item
              </>
            ) : (
              <>
                User{" "}
                <b style={{ color: "rgb(0, 139, 139)" }}>{itemHolderName}</b> is
                looking to buy this item
              </>
            )}
          </p>
          <p className="card-text">
            <strong>Location:</strong> {locationInfo}
          </p>
          <p className="card-text tsm m-0">
            <strong className="tsm">Added:</strong> {formatDate(dateAdded)}
          </p>
          <p className="card-text tsm mb-0">
            <strong className="tsm">Last edited:</strong>{" "}
            {formatDate(lastEdited)}
          </p>
          <p className="card-text tsm mb-4">
            <strong className="tsm">Item id:</strong> {id}
          </p>
        </div>
        <div className="mb-3 mt-3">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              `https://pirpana.com/item/${id}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
            style={{
              backgroundColor: "#4267B2",
              color: "#fff",
              padding: "5px 10px",
              fontSize: "0.8rem",
              borderRadius: "4px",
              lineHeight: "0.8",
              fontFamily: "'Roboto Condensed', sans-serif",
              marginRight: 2,
            }}
          >
            Share on Facebook
          </a>
          <a
            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
              `Check this: https://pirpana.com/item/${id}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success"
            style={{
              backgroundColor: "#25D366",
              color: "#fff",
              padding: "5px 10px",
              fontSize: "0.8rem",
              borderRadius: "4px",
              lineHeight: "0.8",
              fontFamily: "'Roboto Condensed', sans-serif",
              border: "2px solid #25D366",
              marginRight: 2,
            }}
          >
            Share on WhatsApp
          </a>
        </div>
        <div className="image-gallery">
          {itemData?.imagePaths && itemData.imagePaths.length > 0 ? (
            itemData.imagePaths.map((imagePath, index) => (
              <img
                key={index}
                src={`${process.env.REACT_APP_SERVER}/server/${imagePath}`}
                loading="lazy"
                alt={`Item image ${index + 1}`}
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "auto",
                  marginBottom: "10px",
                }}
              />
            ))
          ) : (
            <p>No images</p>
          )}
        </div>
        <div className="border p-0 mt-5 mb-4"></div>
        <a
          href={`/useritems/${itemHolderID}`}
          style={{
            marginTop: "20px",
            color: "black",
            padding: "10px 20px",
            borderRadius: "8px",
            border: "1px solid gray",
            textDecoration: "none",
          }}
        >
          See this user's other items 🗒️
        </a>
        <div className="p-0 mt-4 mb-5"></div>
      </div>
    </div>
  );
};

export default ItemPage;
